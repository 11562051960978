import React,{useEffect,useState} from 'react'
import {Navbar,Form,InputGroup,} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import ExerciseStats from './ExerciseStats'
import Legend from "./Legend";
import OverallStats from './OverallStats'
import {post} from '../lib/helpers'
// import SessionBars from './SessionBars'
// import SessionGraph from './SessionGraph'
import SessionView from './SessionMini'


function Sessions({user}) {
  function exerciseSessions(data, exercise) {
    let result = data.filter(el=>(el.origin===exercise))
    .sort((a, b)=>{
      if (a.timestamp < b.timestamp) {
        return -1;
      }
      if (a.timestamp > b.timestamp) {
        return 1;
      }
      return 0; // a must be equal to b
    })

    let levels = barLevels[exercise]
    let prevDate = new Date(0)
    result.forEach((el,I,A)=>{
      const mob = el.mob
      const thisn = A[I]
      const thisTimestamp = new Date(el.timestamp)

      const dateDiff = (thisTimestamp - prevDate) / 86400000  // :P converting ms to days

      let color = 'grey'
      if (mob > levels.gt) color = '#EEEEEECC'
      if (mob > levels.rgt) color = '#EEB735CC'
      // if (I === 0) color = 'grey'
      if ((dateDiff > 10) && (I > 2)) color = '#777777CC'

      thisn.color = color
      // thisn.x = el.x.toLocaleDateString()
      // thisn.y = el.mob

      prevDate = thisTimestamp
    })

    return result
  }

  function useInput(type="text", def="", classes='') {
    const [value, setValue] = useState(def);
    const input = <input
      className={classes}
      value={value}
      onChange={e => setValue(e.target.value)} type={type}
      placeholder={def}
    />;

    return [value, input];
  }

  const barLevels = {
    "core": {
      gt: 1.0,
      rgt: 1.5,
    },
    "lower": {
      gt: 4.2,
      rgt: 7.2,
    },
    "postural": {
      gt: 2.0,
      rgt: 2.5,
    },
    "upper": {
      gt: 2.0,
      rgt: 2.5,
    },
  }

  let defaultEndDate = new Date()
  defaultEndDate = defaultEndDate.toISOString().slice(0,10)

  let defaultStartDate = new Date()
  defaultStartDate.setMonth(defaultStartDate.getMonth() - 6)
  defaultStartDate = defaultStartDate.toISOString().slice(0,10)

  const [data, setData] = useState([])
  const [endDate, endDateInput] = useInput('date', defaultEndDate, 'date')
  const [startDate, startDateInput] = useInput('date', defaultStartDate, 'date')

  useEffect(() => {
    if (user && user.id) {
      let body = {user_id: user.id}

      post('member/data', body)
      .then(result=>{
        result = result
        .filter(el=>el.valid)
        .map((el,I)=>{
          // const mob = el.data_float
          // const levels = barLevels[el.origin.toLowerCase()]
          const thisTimestamp = new Date(el.timestamp)

          return {
            id: el.id,
            mob: el.data_float,
            force: el.data_int,
            key: el.id,
            origin: el.origin,
            timestamp: new Date(el.timestamp),
            x: thisTimestamp,
            y: el.data_float,
          }
        })

        result = {
          core: exerciseSessions(result,'core'),
          lower: exerciseSessions(result,'lower'),
          postural: exerciseSessions(result,'postural'),
          upper: exerciseSessions(result,'upper'),
        }

        setData(result)
      })
      .catch(error=>console.error(error))
    }

    return () => { }
  }, [user])


  return <>
    <Navbar className="bg-light justify-content-between"
      sticky="top"
    >
      <h3 id="basic-addon1">Your OsteoStrong Data</h3>

      <Form inline="true">
        {startDateInput}
        <h4>&nbsp;through&nbsp;</h4>
        {endDateInput}
      </Form>
    </Navbar>

    <section className="row justify-content-center col-auto">
      <OverallStats data={data} />
    </section>

    {/* <div>
      {startDate} -> {endDate}
    </div>

    <div>
      {defaultStartDate} -> {defaultEndDate}
    </div> */}

    <hr className="fuzzy" />
    <section>
      <ExerciseStats 
        data={data.upper} 
        levels={barLevels.upper}
        origin="upper" 
      />
      <SessionView
        data={data.upper}
        endDate={endDate}
        levels={barLevels.upper}
        origin="upper"
        startDate={startDate}
      />
      <hr className="fuzzy" />
      
      <ExerciseStats 
        data={data.lower} 
        levels={barLevels.lower}
        origin="lower" 
      />
      <SessionView
        data={data.lower}
        endDate={endDate}
        levels={barLevels.lower}
        origin="lower"
        startDate={startDate}
      />
      <hr className="fuzzy" />

      <ExerciseStats 
        data={data.core} 
        levels={barLevels.core}
        origin="core" 
      />
      <SessionView
        data={data.core} 
        endDate={endDate}
        levels={barLevels.core}
        origin="core"
        startDate={startDate}
      />
      <hr className="fuzzy" />

      <ExerciseStats 
        data={data.postural} 
        levels={barLevels.postural}
        origin="postural" 
      />
      <SessionView
        data={data.postural}
        endDate={endDate}
        levels={barLevels.postural}
        origin="postural"
        startDate={startDate}
      />
      <hr className="fuzzy" />
    </section>
    
    <Link
      className='btn btn-primary'
      to={`/report?email=${user.email}&phone=${user.phone}&code=${user.code}`}
    >Report</Link>
    &nbsp;
    <Link
      className='btn btn-primary'
      to={`/progress?email=${user.email}&phone=${user.phone}&code=${user.code}`}
    >Progress</Link>
    
    <Navbar
      className="bg-dark justify-content-between"
      fixed="bottom"
    >
      <Legend />
    </Navbar>
  </>
}

export default Sessions