import React,{useEffect, useState} from 'react'

import {localeWeight, post} from '../lib/helpers'

import {Link} from 'react-router-dom'

import Header from "./Header";
import MiniChart from "./MiniChart";
import MiniNews from "./MiniNews";
import ModeButton from "./ModeButton";
import ReportShare from "./ReportShare";
import useSessionStorage from "../hook/useSessionStorage";

import banner_img from '../img/horiz/OsteoStrong_HorizontalLogo_Color.png'
import printer_icon from "../img/icon/printer.png";
import calendar_icon from "../img/icon/calendar.png";


function Report({user}) {
  const [stats, setStats] = useSessionStorage('member-stats', [])

  const [data, setData] = useState(null)
	const [data_core, setData_core] = useState([])
	const [data_lower, setData_lower] = useState([])
	const [data_postural, setData_postural] = useState([])
	const [data_upper, setData_upper] = useState([])
  const [mode, setMode] = useState('mob')
  const [news, setNews] = useState({})
  const [rawData, setRawData] = useState(null)
  // const [convertedWeight, setConvertedWeight] = useState(X=>X)

  const now = (new Date()).toLocaleDateString()

  // bar-levels are initially set to MOB levels ~ later we calculate force-levels as needed
  const [coreLevels, setCoreLevels] = useState({
    gt: 1.0,
    rgt: 1.5,
  })
  const [lowerLevels, setLowerLevels] = useState({
    gt: 4.2,
    rgt: 7.2,
  })
  const [posturalLevels, setPosturalLevels] = useState({
    gt: 2.0,
    rgt: 2.5,
  })
  const [upperLevels, setUpperLevels] = useState({
    gt: 2.0,
    rgt: 2.5,
  })


  function exerciseSessions(data, exercise) {
    let result = data.filter(el=>(el.origin.toLowerCase()===exercise.toLowerCase()))
    .sort((a, b)=>{
      if (a.timestamp < b.timestamp) {
        return -1;
      }
      if (a.timestamp > b.timestamp) {
        return 1;
      }
      return 0; // a must be equal to b
    })

    result.forEach((el,I,A)=>{
      const thisn = A[I]

      thisn.color = '#EEB735'
    })

    return result
  }


  function printReport() {
    window.print()
  }


  function processData(result) {
    let stuff = result
    .filter(el=>el.valid)
    .map((el,I)=>{
      const thisTimestamp = new Date(el.timestamp)

      user.body_weight = localeWeight(el.data_int / el.data_float)

      return {
        id: el.id,
        mob: el.data_float,
        force: localeWeight(el.data_int),
        key: el.id,
        origin: el.origin,
        timestamp: new Date(el.timestamp),
        x: thisTimestamp,
        y: (mode==='mob')? el.data_float: localeWeight(el.data_int),
      }
    })

    stuff = {
      core: exerciseSessions(stuff, 'core'),
      lower: exerciseSessions(stuff, 'lower'),
      postural: exerciseSessions(stuff, 'postural'),
      upper: exerciseSessions(stuff, 'upper'),
    }

    setData(stuff)
    setData_core(stuff.core)
    setData_lower(stuff.lower)
    setData_postural(stuff.postural)
    setData_upper(stuff.upper)
  }


  function setTriggerLevels() {
    if (user && user.body_weight && mode) {
      if (mode === 'mob') {
        coreLevels.gt = 1.0
        coreLevels.rgt = 1.5
        setCoreLevels(coreLevels)
        
        lowerLevels.gt = 4.2
        lowerLevels.rgt = 7.2
        setLowerLevels(lowerLevels)
  
        posturalLevels.gt = 2.0
        posturalLevels.rgt = 2.5
        setPosturalLevels(posturalLevels)
  
        upperLevels.gt = 2.0
        upperLevels.rgt = 2.5
        setUpperLevels(upperLevels)
      } else {
        coreLevels.gt = 1.0 * +user.body_weight
        coreLevels.rgt = 1.5 * +user.body_weight
        setCoreLevels(coreLevels)
        
        lowerLevels.gt = 4.2 * +user.body_weight
        lowerLevels.rgt = 7.2 * +user.body_weight
        setLowerLevels(lowerLevels)
  
        posturalLevels.gt = 2.0 * +user.body_weight
        posturalLevels.rgt = 2.5 * +user.body_weight
        setPosturalLevels(posturalLevels)
  
        upperLevels.gt = 2.0 * +user.body_weight
        upperLevels.rgt = 2.5 * +user.body_weight
        setUpperLevels(upperLevels)
      }
    }
  }


	useEffect(() => {
    if (user && user.id) {
      let body = {user_id:user.id}

      post('member/data', body)
      .then(result=>{
        setRawData(result)
        processData(result)
      })
      .catch(error=>console.error(error))

      post('member/stats', body)
      .then(result=>{
        setStats(result)
      })
      .catch(error=>console.error(error))
    }
    
    return () => { }
  }, [user])


  useEffect(() => {
    setTriggerLevels()

    if (rawData) processData(rawData)
  }, [mode])


  useEffect(() => {
    setTriggerLevels()
  }, [data, mode, user])


	return ( <div className="report">
    <div className="banner">
      <img alt="" className="logo" src={banner_img} />
    </div>

    <div className="banner print-hide">
      <div className="report date">
        <img alt="" src={calendar_icon} />
        <span>{now}</span>
      </div>

      <ReportShare user={user} />

      <button className="print button print-hide" onClick={printReport}>
        <img alt="" src={printer_icon} />        
        Print
      </button>
    </div>

    <hr />

    <Header data={data} user={user} />

    <ModeButton mode={mode} setMode={setMode} />

    {/* <div className="reports"> */}
      <MiniChart
        levels={upperLevels} 
        data={data_upper}
        mode={mode}
        stats={stats}
        title="Upper GT"
      />

			<MiniChart
				levels={lowerLevels}
				data={data_lower}
        mode={mode}
        showMinimumTrigger={true}
				stats={stats}
        title="Lower GT"
			/>

      <div></div>

      <MiniChart
				levels={coreLevels} 
				data={data_core}
        mode={mode}
				stats={stats}
        title="Core GT"
			/>

			<MiniChart
        breakAfter={true}
				data={data_postural}
				levels={posturalLevels} 
        mode={mode}
				stats={stats}
        title="Postural GT"
			/>

      {(news && news.left)?
        <MiniNews
          news={news.left}
        />
        :<></>
      }

      {(news && news.right)?
        <MiniNews
          news={news.right}
        />
        :<></>
      }
		{/* </div> */}
    <Link
      className='btn btn-primary'
      to={`/data`}
    >Data</Link>
	</div> )
}


export default Report