import React from 'react'

import CGT from '../../img/core_gt.png'
import LGT from '../../img/lower_gt.png'
import PGT from '../../img/postural_gt.png'
import UGT from '../../img/upper_gt.png'

import './MachineSelector.css'

export default function MachineSelector({
  machine,
  setMachine,
}) {
  return (
    <div className='machine-selector'>
      <img
        className={machine === 'core' ? 'highlight' : ''}
        onClick={() => setMachine('core')}
        src={CGT}
        alt="Core Machine"
      />
      <img
        className={machine === 'lower' ? 'highlight' : ''}
        onClick={() => setMachine('lower')}
        src={LGT}
        alt="Lower Machine"
      />
      <img
        className={machine === 'postural' ? 'highlight' : ''}
        onClick={() => setMachine('postural')}
        src={PGT}
        alt="Postural Machine"
      />
      <img
        className={machine === 'upper' ? 'highlight' : ''}
        onClick={() => setMachine('upper')}
        src={UGT}
        alt="Upper Machine"
      />
    </div>
  );
}
