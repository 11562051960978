import {useEffect, useState} from 'react'
import { distance } from "../../lib/helpers";


export default function TestViz({
  breakAfter=true,
  data,
  height = 480,
  index,
  pointRadius = 10,
  title='Balance Test Outcome',
  width = 640,
}) {
  const [accuracy, setAccuracy] = useState(0)
  const [boardSize, setBoardSize] = useState({x:19, y:11})
  const [center, setCenter] = useState({x: width/2, y: height/2})
  const [diameter, setDiameter] = useState(10)
  const [points, setPoints] = useState([])

  
  useEffect(() => {
    if (data && !isNaN(index) && Object.keys(data)?.length) {
      const main = data.data[index]
      const curve = data.curve.filter(X=>X.balance_id===main.id)
      
      const newPoints = []
      
      let topmost, bottommost, leftmost, rightmost, furthestDistance, averageDistance, centerX=0, centerY=0
      
      
      for (let point of curve) {
        const thisX = (point.x / boardSize.x) * width
        const thisY = (point.y / boardSize.y) * height
        
        newPoints.push({
          x: thisX,
          y: thisY,
        })
      }
      
      setPoints(newPoints)
      
      
      // calculate the center of a circle enclosing all the points
      newPoints.forEach(p=>{
        centerX += +p.x
        centerY += +p.y
      })
      const newCenter = {
        x: centerX / newPoints.length,
        y: centerY / newPoints.length,
      }
      setCenter(newCenter)
      
      // calculate the diameter of the enclosing circle
      newPoints.forEach(p=>{
        const thisDistance = distance(newCenter.x, newCenter.y, p.x, p.y)
        
        if (!furthestDistance || (thisDistance > furthestDistance)) {
          furthestDistance = thisDistance
        }
      })
      furthestDistance += pointRadius
      setDiameter(2*furthestDistance)
    }
  }, [data, index])
  
  
  useEffect(() => {
    if (!diameter) return

    // calculate the accuracy of the circle compared to the point-size
    const newAccuracy = (pointRadius / diameter) * 100
    
    setAccuracy(newAccuracy.toFixed(0))
  }, [diameter])
  
  
  return <div className={`mini chart outside-container ${breakAfter? 'page-break': ''}`}>
    <div className="mini balance chart inside-container">
      {data?.curve?.length? <>
        <h2></h2>
        <div className="mini chart title">
          <div>
            <span className="mini chart name accuracy">{title}</span>
          </div>
          
          <div>
            <span className="mini chart name">Accuracy {accuracy}%</span>
          </div>
        </div>

        <svg 
          viewBox={`0 0 ${width} ${height}`}
          style={{backgroundColor: 'antiquewhite'}}
        >
          {points? points.map((X,I)=><circle
            key={I}
            cx={X.x}
            cy={X.y}
            r={pointRadius}
            fill="#AAA8"
            stroke="#A7FA"
            strokeWidth={4}
          />)
          :<></>}
          {/*
          <text x="10" y="50" fill="black" font-size="30" font-family="Roboto" text-anchor="start">
            {diameter.toFixed(2)}
          </text>
           */}
          <circle
            cx={center.x}
            cy={center.y}
            r={diameter / 2}
            fill="#0000"
            stroke="#EEB735" 
            strokeWidth={4}
          />
        </svg>
      </>
      :
        <>no {title.toLowerCase()} data</>
      }
    </div>
  </div>
}
