import React, { useEffect, useRef } from 'react';
import './ModeButton.css';

export default function ModeButton({ mode = 'mob', setMode }) {
  const mobButton = useRef(null);
  const forceButton = useRef(null);

  function setModeMOB(event) {
    setHighlight('mob');
    setMode('mob');
  }

  function setModeForce(event) {
    setHighlight('force');
    setMode('force');
  }

  function setHighlight(thisn) {
    if (mobButton.current && forceButton.current) {
      if (thisn === 'mob') {
        forceButton.current.classList.remove('highlight');
        mobButton.current.classList.add('highlight');
      } else if (thisn === 'force') {
        forceButton.current.classList.add('highlight');
        mobButton.current.classList.remove('highlight');
      }
    }
  }

  useEffect(() => {
    setHighlight(mode);
  }, [mode]);

  return (
    <div className="mode button row">
      <button
          className="mode toggle button"
          onClick={setModeMOB}
          ref={mobButton}
          type="button"
        >
          Multiples of Bodyweight
        </button>
      <div>
        <button
          className="mode toggle button"
          onClick={setModeForce}
          ref={forceButton}
          type="button"
        >
          Force Loaded
        </button>
      </div>
    </div>
  );
}
